type ListItem = {
  title: string;
  description: string;
};

export const costList: ListItem[] = [
  {
    title: "Higher Costs: ",
    description:
      "For those who live on low incomes, many goods and services are simply more expensive than they are for wealthier families. From more expensive financial statements to higher car insurance rates to paying more for everyday goods like toilet paper, people living in poverty spend billions more on many products and services than people with greater income and wealth.",
  },
  {
    title: "Disproportionate Costs: ",
    description:
      "Other costs are technically the same for everyone, but they disproportionately impact people living in poverty. Many of these, like court fines and fees, are predominantly borne by people of color with lower incomes, while others, like security deposits for rental housing, have an outsized downstream impact on people who can’t afford them, reducing housing security, mobility, and affordability.",
  },
  {
    title: "Deferred Costs: ",
    description:
      "Without sufficient liquidity or access to affordable credit, families with lower incomes often have no choice but to purchase cheaper, lower-quality assets. When these assets - such as cars or household appliances - need repairs, families must incur additional expenses to fix or replace them, resulting in a higher total cost of ownership.",
  },
];

export const opportunityList: ListItem[] = [
  {
    title: "Housing",
    description:
      "1 in 4 renters are spending at least 50% of their wages on housing and utilities. Tragically, these costs not only represent a larger portion of the budgets of people with lower incomes, they’re also greater in real terms. Renters with lower incomes, especially those of color, are routinely overcharged relative to market values, and these discrepancies persist for homeowners with Black families paying higher property taxes and receiving more expensive mortgage loans.",
  },
  {
    title: "Transportation",
    description:
      "For families living on low-incomes, finding a way to get around town - to work, to the store, to childcare - can be a major challenge. Buying and maintaining a car can be prohibitively expensive, but the alternative - relying on public transit systems that require multiple transfers to get from Point A to B - can also have prohibitively high time and opportunity costs. And while COVID has transformed the daily commute for many to a short walk down the hallway, the same can’t be said for the 43% of Black and Latinx workers who work in jobs that can’t be done remotely (compared to around 25% for white workers).",
  },
  {
    title: "Employment",
    description:
      "Unpredictable work schedules characterize the professional lives of millions of workers -- especially women of color -- and impose devastating cognitive, physical, and economic costs. The result is an increased incidence of hunger, housing hardships, and psychological distress for families, and higher job turnover for employers. Furthermore, gig workers and employees in many part-time and hourly jobs lack key benefits, like employer-subsidized health, disability, and unemployment insurance. Without these critical supports, the future costs of dealing with health and economic shocks are much much higher. ",
  },
  {
    title: "Health",
    description:
      "More than 25% of U.S. adults age 18-64 struggle to pay their medical bills, and as with so many other costs, this debt disproportionately impacts individuals with lower incomes and people of color. In addition to pushing families into bankruptcy, this debt has a compounding impact on future costs: damaging credit scores and making borrowing more expensive, leading to late fees on missed bills, and increasing future healthcare costs, which arise due to delayed medical care. ",
  },
  {
    title: "Food and retail",
    description:
      "With limited liquidity, individuals living on low incomes are often unable to take advantage of fee-based wholesale services or bulk discounts (e.g., buy two, get one free) at grocery stores, resulting in excess annual costs of over $5 billion. Similarly, due to the inability to purchase furniture and appliances outright, many individuals with lower incomes rely on rent-to-own companies which can often charge twice as much in total costs.",
  },
  {
    title: "Financial services",
    description: `Whether it's exorbitant payday loan interest rates, overdraft fees, or prepaid card fees, individuals with lower incomes face a wide array of costs related to financial services that individuals with higher incomes do not. Estimates of those costs continue to grow each year and were estimated at $196 billion in 2019.`,
  },
];
