import React from "react";
import { Helmet } from "react-helmet";
import { graphql, Link, StaticQuery } from "gatsby";
import Layout from "../../components/Layout";
import Hero from "../../components/Hero";
import { costList, opportunityList } from "../../data/focus-area";
import HeadingAnchor from "../../components/HeadingAnchor";
import Masthead from "../../components/Masthead";
import FooterBackButton from "../../components/FooterBackButton";
import "../../styles/focus-area.scss";

const PageDescription: React.FC = () => (
  <section className="standard-restricted-width">
    <div className="g-max-3-half font-size-3">
      <p className="description">
        Our inaugural cohort of entrepreneurs at Ventures for Shared Prosperity{" "}
        will focus on building businesses that improve the lives of millions by
        reducing the costs for those living in poverty. While most anti-poverty
        efforts focus (justifiably) on increasing incomes and economic
        opportunity, an often-ignored part of the equation is the reality that
        living in poverty in America is{" "}
        <Link to="https://www.ideas42.org/wp-content/uploads/2015/05/I42_PovertyWhitePaper_Digital_FINAL-1.pdf">
          {" "}
          incredibly expensive (PDF).{" "}
        </Link>
      </p>
      <p>
        In partnership with our first cohort of entrepreneurs we will work to
        alleviate the pernicious ways that the “poverty tax” manifests itself.
        Read on if you want to learn more about the problem, and where we see
        opportunities to build large, sustainable, impact businesses.
      </p>
    </div>
  </section>
);

export const Cost = ({
  title,
  description,
}: {
  title: string;
  description: string;
}): React.ReactElement => (
  <li data-testid="cost">
    <p>
      <strong>{title}</strong>
      {description}
    </p>
  </li>
);

export const FocusAreaPage: React.FC<DefaultPageProps> = ({
  location,
  data,
}) => {
  const pageTitle = "ideas42 Venture Studio - The Excess Costs of Poverty";
  return (
    <Layout
      footerChildren={<FooterBackButton to="/studio" text="Back to Studio" />}
    >
      <Helmet htmlAttributes={{ lang: "en" }}>
        <meta charSet="utf-8" />
        <title>{pageTitle}</title>
        <meta
          property="og:url"
          content={data.site.siteMetadata.siteUrl + location.pathname}
        />
        <meta property="og:title" content={pageTitle} />
        <meta name="twitter:title" content={pageTitle} />
      </Helmet>
      <Masthead>
        <Hero
          title="The Excess Costs of Poverty"
          body="There are tens of millions of people in the U.S. who face chronic hunger, housing insecurity, inadequate healthcare, and a daily struggle to make ends meet. The causes of poverty are diverse, complex, and often structural, and the consequences are catastrophic at an individual and societal level."
          bodyClass="hero__body g-max-3-half"
          showBreadcrumbs
        />
        <div className="masthead__graphic--focus-area" />
      </Masthead>
      <PageDescription />
      <article className="standard-restricted-with g-padded">
        <div className="g-max-3 g-centered font-size-2 has-start-rule">
          <HeadingAnchor
            level="2"
            text="The poverty tax"
            id="the-poverty tax"
          />
          <p>
            In order to identify potential business opportunities, it&apos;s
            important to first understand the many different forms that the
            poverty tax takes:
          </p>

          <HeadingAnchor
            text="Direct financial costs"
            level="3"
            id="direct-financial-costs"
          />
          <ul>
            {costList.map((cost) => (
              <Cost
                key={cost.title + cost.description}
                title={cost.title}
                description={cost.description}
              />
            ))}
          </ul>

          <HeadingAnchor
            text="Non-financial costs"
            level="3"
            id="non-financial-costs"
          />
          <p className="font-size-3">
            In addition to financial costs, individuals with lower incomes also
            face higher temporal costs (e.g. spending more time on caregiving),
            administrative costs, and cognitive costs (the near-constant
            trade-off decisions one must make when managing scarce resources)
            than individuals with higher incomes.
          </p>
          <HeadingAnchor
            text="Opportunites"
            level="2"
            id="opportunities"
            className="header__opportunity"
          />
          <p className="font-size-3">
            We have identified six specific facets of life where the poverty tax
            is significant. This list is not meant to be comprehensive, nor is
            it necessarily the case that each of these problems has a credible
            software powered, behavioral science informed solution. Instead,
            they are illustrative of the kinds of problems in the excess costs
            space that we anticipate our first cohort of entrepreneurs will
            explore in partnership with our business design team.
          </p>
          <ul className="opportunity__list">
            {opportunityList.map((o, index) => (
              <li key={o.title}>
                <HeadingAnchor
                  text={`${index + 1}. ${o.title}`}
                  id={o.title}
                  level="3"
                />
                <p className="font-size-3">{o.description}</p>
              </li>
            ))}
          </ul>
          <p className="font-size-3">
            We are deeply aware that the causes of poverty are diverse, complex,
            and often structural. While market-based solutions can improve
            people’s financial health, they are not a panacea. We are incredibly
            proud to be doing this work as part of a broader team - the
            <Link to="https://sharedprosperity.ideas42.org/">
              {" "}
              Shared Prosperity Catalyst
            </Link>
            - that will simultaneously be working to change the narratives
            around poverty in America and shape the policies that can help - and
            too often hurt - people with lower incomes.
          </p>
        </div>
      </article>
    </Layout>
  );
};

export default (props: DefaultPageProps): React.ReactNode => (
  <StaticQuery
    query={graphql`
      query FocusAreaPageQuery {
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `}
    render={(data) => <FocusAreaPage data={data} {...props} />}
  />
);
